
@tailwind base;
@tailwind components;
@tailwind utilities;




* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 1px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 4px;
  border: 2px solid var(--primary);
}


:root {
  --primary: rgb(255, 255, 255) ;
  --secondary:  rgb(188, 188, 194);

}